<template>
  <div class="order-page">
    <el-breadcrumb separator-class="el-icon-right">
      <el-breadcrumb-item :to="{ path: '/admin/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>充值订单</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <div class="page-display">
        <div class="left-panel">
          <el-form :model="pageForm" label-position="top" :rules="pageRules" ref="pageRef" label-width="100px" class="page-form">
            <el-form-item label="充值金额" prop="money">
              <el-input v-model="pageForm.money"></el-input>
            </el-form-item>
            <el-form-item label="选择转账方式" prop="type">
              <el-select v-model="pageForm.type" placeholder="请选择转账方式">
                <el-option label="微信支付" :value="1"></el-option>
                <el-option label="支付宝支付" :value="2"></el-option>
                <el-option label="联系客服支付" :value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="支付凭证" prop="voucher">
              <upload :url="pageForm.voucher" :filed="'voucher'" :max-num="1" @checked-files="checkedAddFiles" @del-select="delAddSelect"></upload>
            </el-form-item>
            <div style="text-align: center;margin-top: 12px;">
              <el-button type="primary" @click="submitForm">提交审核</el-button>
              <el-button @click="resetForm">重置</el-button>
            </div>
          </el-form>
          <div class="to-check">
            <el-button type="success" @click="toLink('https://cdn.1lsq.cn/')" round>查看余额</el-button>
          </div>
        </div>
        <div class="right-panel">
          <el-tabs v-model="activeTab" @tab-click="tabClick">
            <el-tab-pane label="充值指引" name="guide">
              <div class="title">充值流程</div>
              <div class="czlc">
                <el-steps :active="4" align-center>
                  <el-step title="选择付款方式" description="扫码下方对应付款码并支付"></el-step>
                  <el-step title="填写表单" description="左侧表单填写对应本次充值信息"></el-step>
                  <el-step title="提交审核" description="确认表单内容是否正确，并提交审核订单"></el-step>
                  <el-step title="充值结果" description="请等待后台客服确认订单是否已入账"></el-step>
                </el-steps>
              </div>
              <div class="ewm-list">
                <div class="ewm-item">
                  <img :src="orderInfo.wechatEwm"/>
                  <div class="ewm-title">微信支付</div>
                </div>
                <div class="ewm-item">
                  <img :src="orderInfo.aliEwm"/>
                  <div class="ewm-title">支付宝支付</div>
                </div>
                <div class="ewm-item">
                  <img :src="orderInfo.paykf"/>
                  <div class="ewm-title">联系客服</div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="订单列表" name="orderlist">
              <!-- 用户 -->
              <el-table :data="orderInfo.dataList" border style="width: 100%" max-height="500" v-if="orderInfo.role === 1">
                <el-table-column fixed prop="id" label="#" width="60"></el-table-column>
                <el-table-column prop="m_id" label="订单ID" width="120"></el-table-column>
                <el-table-column prop="user_username" label="充值帐号"></el-table-column>
                <el-table-column prop="bucket" label="充值存储桶"></el-table-column>
                <el-table-column prop="money" label="充值金额"></el-table-column>
                <el-table-column prop="voucher" label="支付凭证">
                  <template slot-scope="scope">
                    <el-image class="voucher" style="width: 100px; height: 100px" :preview-src-list="[scope.row.voucher]" :src="scope.row.voucher"></el-image>
                  </template>
                </el-table-column>
                <el-table-column prop="submit_time" label="提交时间">
                  <template slot-scope="scope">
                    <el-tag type="success" effect="plain">{{ $moment(scope.row.submit_time * 1000).format('YYYY-MM-DD HH:mm:ss') }}</el-tag>
                  </template>
                </el-table-column>
                <el-table-column prop="status" label="订单状态">
                  <template slot-scope="scope">
                    <el-tag type="warning" v-if="scope.row.status === 1">待审核</el-tag>
                    <el-tag type="danger" v-if="scope.row.status === 2">充值失败</el-tag>
                    <el-tag type="success" v-if="scope.row.status === 3">充值成功</el-tag>
                  </template>
                </el-table-column>
              </el-table>
              <!-- 管理员 -->
              <el-table :data="orderInfo.dataList" border style="width: 100%" max-height="500" v-if="orderInfo.role === 2">
                <el-table-column fixed prop="id" label="#" width="60"></el-table-column>
                <el-table-column prop="m_id" label="订单ID" width="120"></el-table-column>
                <el-table-column prop="user_username" label="充值帐号"></el-table-column>
                <el-table-column prop="bucket" label="充值存储桶"></el-table-column>
                <el-table-column prop="money" label="充值金额"></el-table-column>
                <el-table-column prop="voucher" label="支付凭证">
                  <template slot-scope="scope">
                    <el-image class="voucher" style="width: 100px; height: 100px" :preview-src-list="[scope.row.voucher]" :src="scope.row.voucher"></el-image>
                  </template>
                </el-table-column>
                <el-table-column prop="submit_time" label="提交时间">
                  <template slot-scope="scope">
                    <el-tag type="success" effect="plain">{{ $moment(scope.row.submit_time * 1000).format('YYYY-MM-DD HH:mm:ss') }}</el-tag>
                  </template>
                </el-table-column>
                <el-table-column prop="status" label="订单状态">
                  <template slot-scope="scope">
                    <el-tag type="warning" v-if="scope.row.status === 1">待审核</el-tag>
                    <el-tag type="danger" v-if="scope.row.status === 2">充值失败</el-tag>
                    <el-tag type="success" v-if="scope.row.status === 3">充值成功</el-tag>
                  </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="150">
                  <template slot-scope="scope">
                    <el-button size="small" type="success" @click="deail(scope.row.m_id, 1)">通过</el-button>
                    <el-button type="danger" size="small" @click="deail(scope.row.m_id, 2)">拒绝</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Upload from '../common/Upload'
export default {
  name: 'Index',
  components: {
    Upload
  },
  data () {
    return {
      pageForm: {
        money: 0,
        type: 1,
        voucher: ''
      },
      pageRules: {
        voucher: [
          { required: true, message: '请上传支付成功的截图', trigger: 'blur' }
        ]
      },
      activeTab: 'guide',
      orderInfo: {
        role: 1,
        dataList: [],
        wechatEwm: '',
        aliEwm: '',
        paykf: ''
      }
    }
  },
  created () {
    this.getDataList()
  },
  methods: {
    // 获取订单列表
    async getDataList () {
      const { data: res } = await this.$http.get('/order-list')
      if (res.code === 200) {
        this.orderInfo.role = res.data.role
        this.orderInfo.dataList = res.data.dataList
        this.orderInfo.wechatEwm = res.data.wechatEwm
        this.orderInfo.aliEwm = res.data.aliEwm
        this.orderInfo.paykf = res.data.paykf
      } else {
        this.$message.error(res.msg)
      }
    },
    submitForm () {
      this.$refs.pageRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post('/order', this.pageForm)
          if (res.code === 200) {
            await this.getDataList()
            this.$notify({
              title: res.msg,
              message: '本次提交订单号为：' + res.data,
              duration: 0,
              type: 'success'
            })
          } else {
            this.$message.error(res.msg)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm () {
      this.$refs.pageRef.resetFields()
    },
    tabClick (tab, event) {
      console.log(tab, event)
    },
    async deail (mid, result) {
      const { data: res } = await this.$http.put('/order', { mid: mid, result: result })
      if (res.code === 200) {
        await this.getDataList()
        this.$message.success(res.msg)
      } else {
        this.$message.error(res.msg)
      }
    },
    checkedAddFiles (data) {
      this.pageForm[data.filed] = data.url
    },
    // 删除已选择文件
    delAddSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.pageForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.pageForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.pageForm[field].splice(checkedIndex, 1)
      }
    },
    toLink (url) {
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped>
  .page-display{
    width: 100%;
    display: flex;
  }
  .page-display .left-panel{
    width: 260px;
    background-color: #FFFFFF;
    padding: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    height: max-content;
  }
  .page-display .right-panel{
    flex: 1;
    margin-left: 40px;
  }
  .page-display .right-panel .title{
    color: #409EFF;
    font-size: 20px;
    font-weight: bold;
    padding: 20px 0 40px 0;
  }
  .page-display .right-panel /deep/.el-step{
    text-align: center;
  }
  .page-display .right-panel /deep/.el-step__title{
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
  }
  /deep/.el-step__icon{
    width: 40px;
    height: 40px;
  }
  /deep/.el-step.is-horizontal .el-step__line{
    top: 20px;
  }
  /deep/.el-step__icon-inner{
    font-size: 24px;
  }
  /deep/.el-step__description{
    text-align: center;
    font-size: 16px;
    margin-top: 6px;
  }
  .ewm-list{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    margin-top: 30px;
  }
  .ewm-item{
    background-color: #FFFFFF;
    padding: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .ewm-item img{
    width: 220px;
    height: 220px;
  }
  .ewm-item .ewm-title{
    font-size: 16px;
    text-align: center;
    margin-top: 6px;
    font-weight: bold;
  }
  .voucher{
    width: 120px;
    height: 120px;
    cursor: pointer;
  }
  .to-check{
    margin-top: 20px;
    text-align: center;
    border-top: 1px dashed rgba(0,0,0,0.1);
    padding-top: 20px;
  }
</style>
